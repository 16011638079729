.NewAnnouncement {
    height: 100%;
    background-color: #f0f2f5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

 .NewAnnouncementWrapper {
    margin-top: 4rem;
    margin-bottom: 4rem;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 }

 .Loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
}
