.ProfileContainer {
    height: 100vh;
    width: 100vw;
    background-color: #f0f2f5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

 .ProfileWrapper {
    margin-bottom: 4rem;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 }

 .ProfileBox {
    height:auto;
    width: 70%;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ProfileTextBox {
    border-width: 0;
    outline: none;
}

.ProfileInfoTitle {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0;
}

.ProfileChangeButton {
    border: #f0f2f5;
    margin-left: 0.5rem;
    background-color: white;
    color: blue;
}
.ProfileChangePasswordButton {
    height: 40px;
    border-radius: 10px;
    border: none;
    background-color: #ea2a55;
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
   
}
