.SubscriptionContainer {
    min-height: 100%;
    width: 100%;
    background-color: #f0f2f5;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

 .SubscriptionWrapper {
    margin-bottom: 4rem;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 }

 .SubscriptionBox {
    height:auto;
    
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 2rem;
}
.Subscription-Card {
    display: flex;
    min-width: 300px;
    flex-direction: column;
}