.NewBanner {
    width: 100vw;
    height: 100vh;
    background-color: #f0f2f5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.NewBannerWrapper{
    width: 70%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.NewBannerBox {
    height:auto;
    width: 70%;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.TitleInfo {
    font-weight: 600;
    margin-top: 0.5rem;
}

.TextFieldInput {
    height: 50px;
    border-radius: 10px;
    border: 1px solid gray;
    font-size: 18px;
    padding-left: 15px;
}

.TextFieldInput:focus {
    outline: none;
}

.PublishButton {
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #ea2a55;
    color: white;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 2rem;
}

.CropContainer {
    height: 100vh;
    width: 100vw;
}

.container-cropper {
    height: 80%;
    padding: 10px;
}

.container-buttons {
    border: 1px solid #f5f5f5;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cropper {
    height: 90%;
    position: relative;
}

.slider {
    height: 10%;
    display: flex;
    align-items: center;
    margin: auto;
    width: 60%;

}

.CropButton {
    margin-top: 5px;
    height: 2rem; 
    font-weight: 600;
    color: blue;
    border-radius: 10px;
    border: none;
}

.CroppedImage {
    margin-top: 2rem;
}


.dateTitle {
    margin-top: 1rem;
    font-weight: 600;
}

.DatePicker {
    padding-left: 10px;
    margin-top: -1rem;
    width: 50%;
    height: 35px;
    border-radius: 3px;
    border-width: 1px;
}

.ManageBannerContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.manageBanner {
    margin: auto;
    width: 63%;
}

.Loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}