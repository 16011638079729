
.contactTitle {
    text-align: center;
    margin-top: 25px;
    color: black;
}

.form-row {
    margin: 25px 85px 75px 100px;
}

.contact-form-label {
    color: black;
    margin-top: 5px;
}

.ContainerBorder{
    @media (max-width: 360px) {
        width: 90%
    }
}