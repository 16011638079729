.ChangePassword {
    height: 100vh;
    width: 100vw;
    background-color: #f0f2f5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

 .ChangePasswordWrapper {
    margin-bottom: 5rem;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 }

 .ChangePasswordBox {
    height:auto;
    width: 70%;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ChangePasswordTitle {
    margin-top: 3rem;
}
.Loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
}